var exports = {};

exports = function (hljs) {
  return {
    subLanguage: "xml",
    contains: [{
      begin: "<%",
      end: "%>",
      subLanguage: "vbscript"
    }]
  };
};

export default exports;